import React from "react"
import { graphql, Link } from "gatsby"
// import { Helmet } from 'react-helmet'

import { MDXRenderer } from "gatsby-plugin-mdx"

import Layout from '../../components/common/layout';
import SEO from '../../components/common/seo';
// import CTA from '../components/blog/cta';
// import Recommended from '../components/blog/recommended';
import AuthorHeader from '../../components/blog/authorheader';
import Subscribe from '../../components/common/subscribe';

import "../../styles/blogpost.css"


// Page for a product walkthrough
export default function BlogPost({data}) {
  const { post, author } = data;  // data.mdx holds your post data

  return (
    <Layout
      // pageTitle={title}
      enableShare={true}
    >
      <SEO title={post.frontmatter.title} description={post.frontmatter.description}
        generateImage={true} path={`/blog/${post.frontmatter.slug}/`}
      />
      
      <div className="content-body markdown-body max-w-5xl mx-auto">
        <h1 className="mb-0 text-center">{post.frontmatter.title}</h1>
        {post.frontmatter.published ? (
          <div className="text-center mt-0">Published {post.frontmatter.published}</div>
        ) : ''}
        
        
        <div className="h-8" />

        <AuthorHeader author={author} post={post} />

        <hr className="bg-gray-100"/>
        <MDXRenderer>{post.body}</MDXRenderer>
        
        <div className="flex">
          <Link to={`/author/${author.frontmatter.slug}/`} className="ml-auto">- {author.frontmatter.name}</Link>
        </div>

        <hr className="bg-gray-100"/>
        <div>
          <Subscribe />
        </div>
      </div>

      
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!, $author: String!) {
    post: mdx(frontmatter: { slug: { eq: $slug }, type: {eq: "post"} }) {
      body
      timeToRead
      excerpt
      frontmatter {
        published
        updated
        author
        authorImage
        slug
        title
        imageUrl
        description
      }
    }
    author: mdx(frontmatter: { slug: { eq: $author }, type: {eq: "author"} }) {
      body
      frontmatter {
        name
        slug
        authorImage
        description
      }
    }
  }
`